<template>
  <main-layout>
    <h2>
      Find out where you can see
      <i>The Cold Hearts</i> next:
    </h2>

    <h3 style="margin-bottom: 1.5em;"><a href="https://www.facebook.com/thecoldhearted317/events" target="_blank">Check here for upcoming dates and times.</a></h3>

    <img alt="Schedule" src="../assets/tchSchedule.jpg" width="100%" />
  </main-layout>
</template>

<script>
import MainLayout from "../layouts/Main.vue";

export default {
  name: "schedule",
  components: {
    MainLayout
  },
}
</script>
